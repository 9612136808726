export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
export const USER_ACTIONS_TYPES = {
  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAIL: "GET_USER_PROFILE_FAIL",
  // =====================================
  POST_USER_LOGIN: "POST_USER_LOGIN",
  POST_USER_LOGIN_SUCCESS: "POST_USER_LOGIN_SUCCESS",
  POST_USER_LOGIN_FAIL: "POST_USER_LOGIN_FAIL",
  // =====================================

  POST_USER_LOGOUT: "POST_USER_LOGOUT",
  POST_USER_LOGOUT_SUCCESS: "POST_USER_LOGOUT_SUCCESS",
  POST_USER_LOGOUT_FAIL: "POST_USER_LOGOUT_FAIL",
  // =====================================
  PUT_USER_PROFILE: "PUT_USER_PROFILE",
  PUT_USER_PROFILE_SUCCESS: "PUT_USER_PROFILE_SUCCESS",
  PUT_USER_PROFILE_FAIL: "PUT_USER_PROFILE_FAIL",
};
export const OWNER_ACTIONS_TYPES = {
  GET_OWNER: "GET_OWNER",
  GET_OWNER_SUCCESS: "GET_OWNER_SUCCESS",
  GET_OWNER_FAIL: "GET_OWNER_FAIL",
  // =====================================
  POST_OWNER: "POST_OWNER",
  POST_OWNER_SUCCESS: "POST_OWNER_SUCCESS",
  POST_OWNER_FAIL: "POST_OWNER_FAIL",
  // =====================================
  PUT_OWNER: "PUT_OWNER",
  PUT_OWNER_SUCCESS: "PUT_OWNER_SUCCESS",
  PUT_OWNER_FAIL: "PUT_OWNER_FAIL",
  // =====================================
  DELETE_OWNER: "DELETE_OWNER",
  DELETE_OWNER_SUCCESS: "DELETE_OWNER_SUCCESS",
  DELETE_OWNER_FAIL: "DELETE_OWNER_FAIL",
  // =====================================
  CLEAR_OWNER: "CLEAR_OWNER",
  CLEAR_OWNER_SUCCESS: "CLEAR_OWNER_SUCCESS",
  CLEAR_OWNER_FAIL: "CLEAR_OWNER_FAIL",
  // =====================================
  GET_OWNER_PROJECTS: "GET_OWNER_PROJECTS",
  GET_OWNER_PROJECTS_SUCCESS: "GET_OWNER_PROJECTS_SUCCESS",
  GET_OWNER_PROJECTS_FAIL: "GET_OWNER_PROJECTS_FAIL",
};
export const OWNERS_ACTIONS_TYPES = {
  GET_OWNERS: "GET_OWNERS",
  GET_OWNERS_SUCCESS: "GET_OWNERS_SUCCESS",
  GET_OWNERS_FAIL: "GET_OWNERS_FAIL",
  // =====================================
  GET_SEARCHED_OWNERS: "GET_SEARCHED_OWNERS",
  GET_SEARCHED_OWNERS_SUCCESS: "GET_SEARCHED_OWNERS_SUCCESS",
  GET_SEARCHED_OWNERS_FAIL: "GET_SEARCHED_OWNERS_FAIL",
};
export const STATISTICS_ACTIONS_TYPES = {
  GET_STATISTICS: "GET_STATISTICS",
  GET_STATISTICS_SUCCESS: "GET_STATISTICS_SUCCESS",
  GET_STATISTICS_FAIL: "GET_STATISTICS_FAIL",
};
export const TYPE_ACTIONS_TYPES = {
  GET_TYPE: "GET_TYPE",
  GET_TYPE_SUCCESS: "GET_TYPE_SUCCESS",
  GET_TYPE_FAIL: "GET_TYPE_FAIL",
  // =====================================
  POST_TYPE: "POST_TYPE",
  POST_TYPE_SUCCESS: "POST_TYPE_SUCCESS",
  POST_TYPE_FAIL: "POST_TYPE_FAIL",
  // =====================================
  PUT_TYPE: "PUT_TYPE",
  PUT_TYPE_SUCCESS: "PUT_TYPE_SUCCESS",
  PUT_TYPE_FAIL: "PUT_TYPE_FAIL",
  // =====================================
  DELETE_TYPE: "DELETE_TYPE",
  DELETE_TYPE_SUCCESS: "DELETE_TYPE_SUCCESS",
  DELETE_TYPE_FAIL: "DELETE_TYPE_FAIL",
  // =====================================
  CLEAR_TYPE: "CLEAR_TYPE",
  CLEAR_TYPE_SUCCESS: "CLEAR_TYPE_SUCCESS",
  CLEAR_TYPE_FAIL: "CLEAR_TYPE_FAIL",
};
export const TYPES_ACTIONS_TYPES = {
  GET_TYPES: "GET_TYPES",
  GET_TYPES_SUCCESS: "GET_TYPES_SUCCESS",
  GET_TYPES_FAIL: "GET_TYPES_FAIL",
  // =====================================
  GET_SEARCHED_TYPES: "GET_SEARCHED_TYPES",
  GET_SEARCHED_TYPES_SUCCESS: "GET_SEARCHED_TYPES_SUCCESS",
  GET_SEARCHED_TYPES_FAIL: "GET_SEARCHED_TYPES_FAIL",
};
export const PROJECT_ACTIONS_TYPES = {
  GET_PROJECT: "GET_PROJECT",
  GET_PROJECT_SUCCESS: "GET_PROJECT_SUCCESS",
  GET_PROJECT_FAIL: "GET_PROJECT_FAIL",
  // =====================================
  POST_PROJECT: "POST_PROJECT",
  POST_PROJECT_SUCCESS: "POST_PROJECT_SUCCESS",
  POST_PROJECT_FAIL: "POST_PROJECT_FAIL",
  // =====================================
  PUT_PROJECT: "PUT_PROJECT",
  PUT_PROJECT_SUCCESS: "PUT_PROJECT_SUCCESS",
  PUT_PROJECT_FAIL: "PUT_PROJECT_FAIL",
  // =====================================
  DELETE_PROJECT: "DELETE_PROJECT",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAIL: "DELETE_PROJECT_FAIL",
  // =====================================
  CLEAR_PROJECT: "CLEAR_PROJECT",
  CLEAR_PROJECT_SUCCESS: "CLEAR_PROJECT_SUCCESS",
  CLEAR_PROJECT_FAIL: "CLEAR_PROJECT_FAIL",
};
export const PROJECTS_ACTIONS_TYPES = {
  GET_PROJECTS: "GET_PROJECTS",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAIL: "GET_PROJECTS_FAIL",
};
export const NOTIFICATIOS_ACTIONS_TYPES = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAIL: "GET_NOTIFICATIONS_FAIL",

  // ======================================================
  GET_NOTIFICATIONS_DROPDOWN: "GET_NOTIFICATIONS_DROPDOWN",
  GET_NOTIFICATIONS_DROPDOWN_SUCCESS: "GET_NOTIFICATIONS_DROPDOWN_SUCCESS",
  GET_NOTIFICATIONS_DROPDOWN_FAIL: "GET_NOTIFICATIONS_DROPDOWN_FAIL",
  // ======================================================
  PUT_NOTIFICATION: "PUT_NOTIFICATION",
  PUT_NOTIFICATION_SUCCESS: "PUT_NOTIFICATION_SUCCESS",
  PUT_NOTIFICATION_FAIL: "PUT_NOTIFICATION_FAIL",
};
export const PAYMENTS_ACTIONS_TYPES = {
  GET_PAYMENTS: "GET_PAYMENTS",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAIL: "GET_PAYMENTS_FAIL",
};
export const PAYMENT_ACTIONS_TYPES = {
  GET_PAYMENT: "GET_PAYMENT",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAIL: "GET_PAYMENT_FAIL",

  // =======================================
  POST_PAYMENT: "POST_PAYMENT",
  POST_PAYMENT_SUCCESS: "POST_PAYMENT_SUCCESS",
  POST_PAYMENT_FAIL: "POST_PAYMENT_FAIL",
  // =======================================
  PUT_PAYMENT: "PUT_PAYMENT",
  PUT_PAYMENT_SUCCESS: "PUT_PAYMENT_SUCCESS",
  PUT_PAYMENT_FAIL: "PUT_PAYMENT_FAIL",
  // =======================================
  DELETE_PAYMENT: "DELETE_PAYMENT",
  DELETE_PAYMENT_SUCCESS: "DELETE_PAYMENT_SUCCESS",
  DELETE_PAYMENT_FAIL: "DELETE_PAYMENT_FAIL",
  // =======================================
  CHECK_PAYMENT_PAID: "CHECK_PAYMENT_PAID",
  CHECK_PAYMENT_PAID_SUCCESS: "CHECK_PAYMENT_PAID_SUCCESS",
  CHECK_PAYMENT_PAID_FAIL: "CHECK_PAYMENT_PAID_FAIL",
  // =======================================
  CLEAR_PAYMENT: "CLEAR_PAYMENT",
  CLEAR_PAYMENT_SUCCESS: "CLEAR_PAYMENT_SUCCESS",
  CLEAR_PAYMENT_FAIL: "CLEAR_PAYMENT_FAIL",
};
